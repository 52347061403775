<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        to="/"
      >
        <!-- <vuexy-logo /> -->
        <custom-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ navbarTitle }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-overlay
          :show="show"
          rounded="sm"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
            >
              {{ $t('Welcome to') }} {{ navbarTitle }}! 👋
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t('Please sign-in to your account') }}
            </b-card-text>
            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- username -->
                <b-form-group
                  :label="$t('Username')"
                  label-for="login-username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    vid="username"
                    rules="required"
                  >
                    <b-form-input
                      id="login-username"
                      v-model="username"
                      :state="errors.length > 0 ? false : null"
                      name="login-username"
                      :placeholder="$t('Username')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ $t("Password") }}</label>
                    <b-link :to="{ name: 'auth-forgot-password' }">
                      <small>{{ $t("Forgot Password") }}?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        :placeholder="$t('Password')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="remember"
                    name="checkbox-1"
                  >
                    {{ $t("Remember Me") }}
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t("Sign in") }}
                </b-button>
              </b-form>
            </validation-observer>

          </b-col>
        </b-overlay>

      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import CustomLogo from '@/Logo.vue'
import {
  BOverlay,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import {
  // eslint-disable-next-line no-unused-vars
  initialAbility, storeGroceryAbility, storeFoodAbility, storeQueueAbility, driverDeliveryAbility, driverCallAbility, servicesAbility,
} from '@/libs/acl/config'
// eslint-disable-next-line no-unused-vars
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

// eslint-disable-next-line no-unused-vars
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VuexyLogo,
    CustomLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // eslint-disable-next-line no-unneeded-ternary
      remember: process.env.NODE_ENV === 'production' ? false : true,
      username: process.env.NODE_ENV === 'production' ? '' : '0986816378',
      password: process.env.NODE_ENV === 'production' ? '' : '123456',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      show: false,
      // validation rules
      required,
    }
  },
  computed: {
    navbarTitle() {
      return store.state.appConfig.navbarTitle
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    useJwt.logout()
    // Reset ability
    this.$ability.update(initialAbility)
    store.commit('appConfig/UPDATE_MENU_SERVICE', '')
    store.commit('appConfig/RESET_NAVBAR_TITLE')
    store.commit('appConfig/RESET_NAVBAR_IMAGE')
  },
  methods: {
    changeType(type) {
      this.type = type
      localStorage.setItem('buttonLogin', type)
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.show = true
          useJwt
            .login({
              username: this.username,
              password: this.password,
              remember: this.remember,
            })
            .then(response => {
              this.show = false

              console.log('response.data')
              console.log(response.data)
              // const userData = response.data.data
              this.$router.replace({ name: 'select-user', query: { token: response.data.data.token, id: response.data.data._id } }).catch(() => {})
            }).catch(() => {
              this.show = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
